import {graphql, Link, navigate, useStaticQuery} from 'gatsby';
import React from 'react';
import type {PropsWithChildren} from 'react';

import {MongoPassport, underscore} from '@travelfreedom/shared';

import {SelectCountry, Layout, SEO} from '../components';
import {Passport} from '../library/common';
import {buildOrderedRankingBuckets, SingleRanking} from '../library/evaluation';
import {CountryPill} from '../components/country-pill';

type LinkProps = PropsWithChildren<{
  to: string;
  className?: string | undefined;
}>;
const defaultLinkProps = {
  className: '',
};

const PrimaryLink = ({to, children, className}: LinkProps): JSX.Element => {
  return (
    <Link
      className={`bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2 ${className}`}
      to={to}
    >
      {children}
    </Link>
  );
};
PrimaryLink.defaultProps = defaultLinkProps;

const InfoLink = ({to, children, className}: LinkProps): JSX.Element => {
  return (
    <Link
      className={`bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-2 ${className}`}
      to={to}
    >
      {children}
    </Link>
  );
};
InfoLink.defaultProps = defaultLinkProps;

type BoxWithTitleProps = PropsWithChildren<{
  title: string;
  className?: string | undefined;
}>;
const BoxWithTitle = ({title, children, className}: BoxWithTitleProps): JSX.Element => {
  return (
    <div
      className={`shadow border bg-white border-gray-200 sm:rounded-lg my-4 min-w-0 ${className}`}
    >
      <div className="px-4 py-2 bg-gray-100">{title}</div>
      <div className="inline-flex flex-wrap p-2">{children}</div>
    </div>
  );
};
BoxWithTitle.defaultProps = {
  className: '',
};

export const IndexPage = (): JSX.Element => {
  const {allMongodbTravelfreedomPassports} = useStaticQuery(graphql`
    query {
      allMongodbTravelfreedomPassports(sort: {fields: name}) {
        nodes {
          globalCode
          name
          adjective
          ranking {
            rank
          }
        }
      }
    }
  `);

  const allPassports: Passport.Data[] = allMongodbTravelfreedomPassports.nodes
    .map((node: MongoPassport) => Passport.fromLeanJson(node))
    .filter((d: Passport.Data) => d.type === 'ORDINARY');
  const winners: SingleRanking = Object.assign(
    {},
    ...allPassports
      .filter((p) => p.ranking.rank <= 5)
      .map((p) => ({
        [p.globalCode]: p.ranking.rank,
      }))
  );
  const winnersTable = buildOrderedRankingBuckets(winners, allPassports);

  return (
    <Layout>
      <SEO title="TravelFreedom.io" pathname="/" />
      <div className="grid lg:grid-cols-2 lg:gap-8">
        <div className="mt-2 min-w-0">
          <div className="my-2">Choose your passport and see where you can travel!</div>
          <SelectCountry
            passports={allPassports}
            onSelect={(passport) => {
              navigate(`/passport/${underscore(passport.name)}`);
            }}
          />
          <BoxWithTitle title="All Travel Documents">
            <PrimaryLink to="/passport/">Passports</PrimaryLink>
            <PrimaryLink to="/official_passport/">Official Passports</PrimaryLink>
            <PrimaryLink to="/diplomatic_passport/">Diplomatic Passports</PrimaryLink>
            <PrimaryLink to="/national_id/">National IDs</PrimaryLink>
          </BoxWithTitle>
          <BoxWithTitle title="More Tools">
            <PrimaryLink to="/destination/">Destinations</PrimaryLink>
            <InfoLink to="/compare/">Compare</InfoLink>
          </BoxWithTitle>
        </div>

        <div className="min-w-0">
          {/* <div className="my-1"><GoogleAd /></div> */}
          <BoxWithTitle title="Best Passports" className="mt-0 lg:mt-4">
            <table className="bg-white table-auto divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rank
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {winnersTable.map((rti, index) => (
                  <tr key={rti.documents.reduce((a, c) => `${a}${c.globalCode}`, '')}>
                    <td className="px-6 py-2">
                      <span>{index + 1}</span>
                    </td>
                    <td className="px-6 py-2">
                      {rti.documents.map((passport) => (
                        <CountryPill
                          key={passport.globalCode}
                          name={passport.name}
                          code={passport.code}
                          href={Passport.hrefPath(passport)}
                        />
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PrimaryLink to="/ranking">Full Ranking</PrimaryLink>
            <InfoLink to="/compare">Compare</InfoLink>
          </BoxWithTitle>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
